import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import Truncate from 'react-truncate';

const variants = {
  show: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
};

const Header = ({
  mode,
  value,
  bold,
  className,
  truncate,
  lines,
  white,
  isShow,
}) => {
  const textSize = () => {
    switch (mode) {
      case 'h1':
        return 'text-3xl xl:text-4xl';
      case 'h2':
        return 'text-2xl xl:text-2xl';
      default:
        return 'text-3xl xl:text-4xl';
    }
  };

  const textClasses = `${textSize()} ${
    white ? 'text-white' : 'text-primary '
  } ${bold ? 'font-bold' : ''} ${className}`;

  const trucateTextClasses = `${
    white ? 'text-white' : 'text-primary '
  }  text-lg md:text-xl xl:text-2xl ${bold ? 'font-bold' : ''}${className}`;
  if (truncate) {
    switch (mode) {
      case 'h1':
        return (
          <Truncate
            className={textClasses}
            lines={lines}
            ellipsis={<span>...</span>}
          >
            <h1>{value}</h1>
          </Truncate>
        );
      case 'h2':
        return (
          <h2 className={trucateTextClasses}>
            <Truncate lines={lines} ellipsis={<span>...</span>}>
              {value}
            </Truncate>
          </h2>
        );
      default:
        return <div>nothing...</div>;
    }
  }

  switch (mode) {
    case 'h1':
      return (
        <motion.h1
          className={textClasses}
          variants={variants}
          initial={'hidden'}
          animate={isShow ? 'show' : 'hidden'}
        >
          {value}
        </motion.h1>
      );
    case 'h2':
      return (
        <motion.h2
          className={textClasses}
          variants={variants}
          inherit={'hidden'}
          animate={isShow ? 'show' : 'hidden'}
        >
          {value}
        </motion.h2>
      );
    default:
      return <div>nothing...</div>;
  }
};

export default Header;

Header.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.string,
  bool: PropTypes.bool,
  className: PropTypes.string,
  truncate: PropTypes.bool,
  lines: PropTypes.number,
  white: PropTypes.bool,
  isShow: PropTypes.bool,
};
Header.defaultProps = {
  mode: 'h1',
  value: '',
  bool: false,
  className: '',
  truncate: false,
  lines: 1,
  white: false,
  isShow: true,
};
